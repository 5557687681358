<template>

  <section class="banner-section">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-md-10 col-lg-8 mx-auto text-center">
          <h2 class="text-white mb-5"></h2>
        </div>
      </div>
    </div>
  </section>

  <section class="anchor-section bg-light">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-lg-3 col-md-6 col-sm-12 text-center text-justify">
          <a href="#about">About CryptoR.AT</a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 text-center text-justify">
          <a href="#tweets">Latest Tweets</a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 text-center text-justify">
          <a href="#customisable-nfts">Customisable NFTs</a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 text-center text-justify">
          <a href="#team">Meet the Team</a>
        </div>
      </div>
    </div>
  </section>

  <!--section class="projects-section bg-dark" id="projects">
    <div class="container px-4 px-lg-5">
      <div class="row gx-0 justify-content-center">
        <div class="col-lg-6">
          <div class="bg-black text-center h-100 project">
            <div class="d-flex h-100">
              <div class="project-text w-100 my-auto text-center text-lg-left">
                <p class="mb-0 text-white-50">
                  <img src="images/bitcoin-cash-logo-horizontal.png" class="bch-logo img-fluid" />
                </p>
                <h3>Original collection</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="bg-black text-center h-100 project">
            <div class="d-flex h-100">
              <div class="project-text w-100 my-auto text-center text-lg-right project-nightmares">
                <p class="mb-0 text-white-50">
                  <img src="images/dogechain.png" class="bch-logo img-fluid" />
                </p>
                <h3>
                  <a href="https://nightmares.cryptor.at" target="_blank" style="color: rebeccapurple; text-decoration: none;">Nightmares Edition &raquo;</a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section-->

  <section class="about-rats-section">
    <a id="about"></a>
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-lg-4 text-center text-justify">
          <p>
            <img v-if="random" class="rat-image img-fluid" v-bind:src="'https://cryptor.at/original/' + random" />
          </p>
        </div>
        <div class="col-lg-8">
          <h2>About CryptoR.AT</h2>
          <p>
            $RAT; a set of <a href="https://en.wikipedia.org/wiki/Fancy_rat" target="_blank">fancy $RATs</a> created on the Bitcoin Cash blockchain using the SimpleLedger Protocol, and even spread to SmartBCH with its bridge.
          </p>
          <p>
            This project began in April 2021 as an experiment, something Bitcoin Cash had not yet seen before; generated pixel-art-style animals with all kinds of attributes and rarities.<br/> Drawn & built completely from the ground up by <a href="https://shadowkite.io">@Shadowkite</a>.
          </p>
          <p>
            A faucet was made to distribute them for free, to give everyone a chance to own one, give them away, send to friends, sell or trade them.
          </p>
          <p>
            The *original* $RAT set had 10.000 unique tokens. About 5.400 $RATS were bridged to SmartBCH. <br/>
            The <strong>Nightmares</strong> Collection on DogeChain, launched August 2022, has 1.000 $RATs.
          </p>
        </div>
        <div class="col-lg-4">
        </div>
        <div class="col-lg-8">
          <div class="container">
            <div class="row">
              <h2>Notable $RATs</h2>
              <div class="col-lg-3 col-md-6 col-sm-12 text-center">
                <img src="https://api.cryptor.at/original/rat/7975" class="img-fluid rat-image" /><br/>
                <span style='height:60px;display:block;'>Peaches [#7975]</span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 text-center">
                <img src="https://api.cryptor.at/original/rat/4088" class="img-fluid rat-image" /><br/>
                <span style='height:60px;display:block;'>Ferry [#4088]</span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 text-center">
                <img src="https://api.cryptor.at/original/rat/1356" class="img-fluid rat-image" /><br/>
                <span style='height:60px;display:block;'>Wellington [#1356]</span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 text-center">
                <img src="https://api.cryptor.at/original/rat/1" class="img-fluid rat-image" /><br/>
                <span style='height:60px;display:block;'>Nexus [#1]</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="content-section">
    <a id="tweets"></a>
    <div class="container px-4 px-lg-5">
      <div class="col-lg-12 text-center">
        <h2 class="text-white mb-4">Latest tweets.</h2>
      </div>
      <div class="row gx-4 gx-lg-5 justify-content-center">
        <div class="col-8">
          <a class="twitter-timeline" data-height="600" data-theme="dark" data-chrome="transparent" href="https://twitter.com/cryptoratnft?ref_src=twsrc%5Etfw"></a>
        </div>
      </div>
    </div>
  </section>

  <section class="about-rats-section">
    <a id="customisable-nfts"></a>
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-12 col-lg-8 col-md-8">
          <h2>Customisable NFTs</h2>
          <p>
            Halloween inspired us to create a new type of collection, dress-up your NFT!<br/>
            With a small supply of $RATs, we launched on DogeChain in August 2022. With newly drawn traits added, the collection sold out within hours. <br/>
            We felt SmartBCH deserved the same treatment, and we upgraded the collection with a dress-up room as well.
          </p>
          <p>
            Owners of $RAT-NFTs can customise their $RAT by equipping traits.<br/>
            These separate trait-NFTs can be equipped and unequipped at any time by using the <span style="white-space: nowrap">"Fitting room"-feature</span>. <br/>
            $RATs can be traded with or without traits, and traits can be traded separately through decentralised marketplaces like <a href="https://app.withmantra.com" target="_blank">Mantra</a> or <a href="https://oasis.cash">Oasis.cash</a> (sBCH) / <a href="https://oasis-nft.dog">Oasis-NFT.dog</a> (DogeChain).
          </p>
          <p>
            Expanding on this idea, we are now selling new traits individually in the <a href="/shop">Shop</a>. This will generate income for the project and make it more sustainable long-term. It will give us the opportunity to do games and raffles for the community, and more developments in the future.
          </p>
        </div>
        <div class="col-12 col-lg-4 col-md-4 text-center">
          <p class="text-center">
            <img class="rat-image img-fluid" src="https://api.cryptor.at/nightmares/rat/1,1001,2001" />
          </p>
          <p class="text-center">
            <img src="https://api.cryptor.at/nightmares/rat/1" width="30%" class="rat-image img-fluid" />&nbsp;
            <img src="https://api.cryptor.at/nightmares/rat/1001" width="30%" class="rat-image img-fluid" />&nbsp;
            <img src="https://api.cryptor.at/nightmares/rat/2001" width="30%" class="rat-image img-fluid"/>
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="projects-section bg-dark" id="projects">
    <a id="team"></a>
    <div class="container px-4 px-lg-5">
      <div class="col-lg-12 text-center">
        <h2 class="text-white mb-4">Meet The Team</h2>
      </div>
      <div class="row gx-0 justify-content-center">
        <div class="col-lg-6">
          <div class="bg-black text-center h-100 project">
            <div class="d-flex h-100">
              <div class="project-text w-100 my-auto text-center text-lg-left team-shadowkite">
                <h2 class="text-white">Shadowkite</h2>
                <small class="text-white">Artist & Developer</small>
                <p></p>
                <p>
                  <a href="https://t.me/shadowkite" target="_blank">Drop me a message</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="bg-black text-center h-100 project">
            <div class="d-flex h-100">
              <div class="project-text w-100 my-auto text-center text-lg-left team-insanity">
                <h2 class="text-white">Insanity</h2>
                <small class="text-white">Community manager</small>
                <p></p>
                <p>
                  <a href="https://t.me/insanity3431" target="_blank">Drop me a message</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


</template>

<style>
.memes a {
  display: block;
  height: 2em;
}

.memes .image a {
  display: inline;
  height: 300px;
}

.memes .image {
  height: 300px;
  vertical-align: middle;
  display: table-cell;
}
</style>
<script>
export default {
  data() {
    return {
      random: null,
    }
  },
  setup() {
    let twitterScript = document.createElement('script')
    twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
    document.head.appendChild(twitterScript)
  },
  mounted() {
    fetch(this.api_url + '/api/randomRat').then(function(response) {
      return response.json();
    }).then(function(result) {
      this.app.random = result.random;
    }.bind({app: this}));
  }
}
</script>